<template>
    <div class="d-flex bottom-0 gap-4 justify-content-center w-100" :class="{'position-absolute' : absolute}">
        <a v-for="item of footerLinks" :href="item.link" target="_blank" class="d-flex align-items-center mb-3 gap-1 text-secondary">
            <p class="m-0 text-footer">{{item.name}}</p>
        </a>
    </div>
</template>

<script setup>

const {t} = useI18n()

const props = defineProps({
    absolute: {
        type: Boolean,
        default: true
    }
})

const footerLinks = [
    {
        name: t('nav.privacy.name'),
        link: t('nav.privacy.url')
    },
    {
        name: t('nav.imprint.name'),
        link: t('nav.imprint.url')
    },
    {
        name: t('nav.termsConditions.name'),
        link: t('nav.termsConditions.url')
    }
]
</script>

<style scoped>
.text-footer {
    font-size: 0.9rem;
}
</style>